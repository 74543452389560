<template>
  <div id="uploadDate">
    <slot name="uploadTitle">
    </slot>
    <div class="van-hairline--bottom"></div>
    <div class="selectUpload">
      <div class="ListLeft">
        <van-uploader max-count="6" :after-read="afterRead" multiple>
          <svg t="1604042891392" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3236" width="30" height="30">
            <path d="M567.466667 634.325333v234.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-234.666667H413.866667a8.533333 8.533333 0 0 1-6.826667-13.653333l110.933333-147.925333a8.533333 8.533333 0 0 1 13.653334 0l110.933333 147.925333a8.533333 8.533333 0 0 1-6.826667 13.653333h-68.266666z" fill="#707070" p-id="3590"></path>
            <path d="M768 725.333333a128 128 0 0 0 38.613333-250.112l-39.850666-12.586666-14.506667-39.253334a256.128 256.128 0 0 0-480.554667 0l-14.464 39.253334-39.850666 12.586666A128.085333 128.085333 0 0 0 256 725.333333a42.666667 42.666667 0 0 1 0 85.333334 213.333333 213.333333 0 0 1-64.341333-416.810667 341.461333 341.461333 0 0 1 640.682666 0A213.418667 213.418667 0 0 1 768 810.666667a42.666667 42.666667 0 0 1 0-85.333334z" fill="#707070" p-id="3591"></path>
          </svg>
        </van-uploader>
        <p class="typeTitle">上传照片</p>
      </div>
      <div class="ListRight" v-if="uploadDateReady">
        <div class="imgItem" v-for="(item,index) in uploadImageList" :key="index" :style="`background: url('${cdnHttp}${item}?imageView2/1/format/webp/q/90') no-repeat center / cover`"  @click="copyUrl(item)">
        </div>
      </div>
      <div class="ListRight" v-else>
        <van-loading type="spinner" color="#ff816c" />
      </div>
    </div>
    <div class="selectUpload">
      <div class="ListLeft">
        <van-uploader max-count="6" :after-read="afterReadRadio" multiple accept="video/*">
          <svg t="1604053282932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3589" width="30" height="30">
            <path d="M567.466667 634.325333v234.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-234.666667H413.866667a8.533333 8.533333 0 0 1-6.826667-13.653333l110.933333-147.925333a8.533333 8.533333 0 0 1 13.653334 0l110.933333 147.925333a8.533333 8.533333 0 0 1-6.826667 13.653333h-68.266666z" fill="#707070" p-id="3590"></path>
            <path d="M768 725.333333a128 128 0 0 0 38.613333-250.112l-39.850666-12.586666-14.506667-39.253334a256.128 256.128 0 0 0-480.554667 0l-14.464 39.253334-39.850666 12.586666A128.085333 128.085333 0 0 0 256 725.333333a42.666667 42.666667 0 0 1 0 85.333334 213.333333 213.333333 0 0 1-64.341333-416.810667 341.461333 341.461333 0 0 1 640.682666 0A213.418667 213.418667 0 0 1 768 810.666667a42.666667 42.666667 0 0 1 0-85.333334z" fill="#707070" p-id="3591"></path>
          </svg>
        </van-uploader>
        <p class="typeTitle">上传视频</p>
      </div>
      <div class="ListRight" v-if="uploadDateVReady">
        {{tipMessage}}
      </div>
      <div class="ListRight" v-else>
        <van-loading type="spinner" color="#ff816c" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    httpUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileList: [],
      uploadImageList: [],
      videoId: "",
      uploadDateReady: true,
      uploadDateVReady: true,
      tipMessage: "",
    };
  },
  created() {
    console.log(this.$route);
    console.log(this.cdnHttp);
  },
  methods: {
      copy(data) {
      let _data = data;
      let newInput = document.createElement("input");
      newInput.value = _data;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$toast("复制完毕");
    },
    copyUrl(item) {
      this.copy(`${this.cdnHttp}${item}`);
    },
    afterRead(file, type) {
      this.uploadDateReady = false;
      let imageFormData = new FormData();
      console.log(file);
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          imageFormData.append(`files[${i}]`, file[i].file);
        }
      } else {
        imageFormData.append(`files[0]`, file.file);
      }
      imageFormData.append("type", 1);
      this.$http
        .post("/api/common/uploadOther", imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res, "ssss");
          // console.log(res.data.result);
          // console.log(res.data.result.url);
          res.data.result.forEach((item, index) => {
            this.uploadImageList.push(item.url);
          });
          this.$emit("getImageList", this.uploadImageList);
          this.uploadDateReady = true;
        });
    },
    afterReadRadio(file) {
      this.uploadDateVReady = false;
      let videoFormDate = new FormData();
      console.log(file);
      videoFormDate.append(`files[0]`, file.file);
      videoFormDate.append("type", 2);
      this.$http
        .post("/api/worker/uploadInspection", videoFormDate, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data.result[0].id);
          this.$emit("getVideoId", res.data.result[0].id);
          this.uploadDateVReady = true;
          this.tipMessage = "视频上传成功";
        })
        .catch((err) => {
          console.log(err);
          this.videoId = err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
#uploadDate {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 46px;
  .uploadDateTitle {
    font-size: 1.6rem;
    padding: 1.2rem 0;
    text-align: center;
    span {
      font-size: 1.4rem;
      color: #b0b0b0;
      padding-left: 0.85rem;
    }
  }
  .selectUpload {
    padding: 1rem;
    display: flex;
    align-items: center;
    .ListLeft {
      padding-right: 1rem;
      width: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .ListRight {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      .imgItem {
        flex: 0 0 auto;
        width: 9rem;
        height: 9rem;
        margin-right: 0.4rem;
        border-radius: 4px;
      }
    }
    .ListRight::-webkit-scrollbar {
      width: 0px;
    }
    .typeTitle {
      font-size: 1.4rem;
      padding: 0.5rem 0;
    }
  }
}
</style>