<template>
  <div id="delUsers">
    <van-cell-group>
      <van-field v-model="value" center clearable placeholder="填写需删除注册的手机号">
        <template #button>
          <van-button size="small" type="primary" @click="delUser">仅限测试使用删除</van-button>
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    delUser() {
      this.$ajax
        .get(`/api/delete?mobile=${this.value}`)
        .then((res) => {
          window.localStorage.clear();
        });
    },
  },
};
</script>
<style lang="less">
</style>