<template>
  <div id="uploadOther">
    <p class="tip">
      资源类图片自主上传
    </p>
    <van-notice-bar mode="closeable">
      点击图片，可以复制地址链接哦！
    </van-notice-bar>
    <UploadOther @image="getFormData" @getImageList="getImageList">
      <template #uploadTitle>
      </template>
    </UploadOther>
    <DeleatUser />
    <van-field v-model="certification" rows="1" autosize label="审核" type="textarea" placeholder="请输入token" />
    <van-button type="primary" block @click="certification">实名认证审核</van-button>
  </div>
</template>
<script>
import UploadOther from "@/components/effect/UploadOther.vue";
import DeleatUser from "@/components/effect/DeleatUser.vue";
export default {
  data() {
    return {
      FormData: {},
      uploadImageList: [],
      activeName: "1",
      certification:''
    };
  },
  components: {
    UploadOther,
    DeleatUser,
  },
  methods: {
    getFormData(e) {
      this.FormData = e;
    },
    copy(data) {
      let _data = data;
      let newInput = document.createElement("input");
      newInput.value = _data;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$toast("复制完毕");
    },
    copyUrl(item) {
      this.copy(`${this.cdnHttp}${item}`);
    },
    getImageList(e) {
      console.log(e);
      this.uploadImageList = e;
    },
    certification() {
     this.$http.put('/api/v2/worker/profile/certification').then(res => {console.log(res)})
    }
  },
};
</script>
<style lang="less"scoped>
.tip {
  padding: 2rem 0;
  font-size: 1.6rem;
  text-align: center;
  background: #fff;
  font-weight: 600;
}
.imglist {
  width: 100%;
}
</style>